import axios from '../common/axios.js'
import _ from 'lodash'
import CustomSelect from '../common/forms/Select.vue'

export default {
	data() {
		return {
			user: null,
			errors: {},
			success: false,
			telechargements: {},
			annee: 0,
			telechargement: {},
			current_profil: 'tous',
		}
	},
	created(){
		let sourcing_id = this.$store.state.ui.sourcing.id;
		let self = this
		axios.get('api=inex/views/connections-credits/{view_code}')
			.then(function (response) {
				if (response.data.annees.length) {
					self.telechargements = response.data
					self.annee = response.data.annees[response.data.annees.length-1]
				}
			}).catch(function (error) {

			})
	},
	methods: {
		checkError(code){
			return 'aaa'
		},
		close_filter(){
			this.$store.commit('UI_SET_CONTENT', {content: 'result'});
		}		
	},
	computed:{
		getTelechargement(){
			let profil_id = this.current_profil;
			let data = this.annee ? this.telechargements.data_by_years_months[this.annee] : {}
			
			if (profil_id != 'tous') {
				data = this.annee ? this.telechargements.data_profils[profil_id].data_by_years_months[this.annee] : {}
			}

			return data
		},
		getTotalConnexion(){
			let profil_id = this.current_profil;
			let data = this.annee ? this.telechargements.data_by_years[this.annee]['total_connexion'] : 0;
			
			if (profil_id != 'tous') {
				data = this.annee ? this.telechargements.data_profils[profil_id].data_by_years[this.annee]['total_connexion'] : 0;
			}

			return data
		},
		getTotalAcces(){
			let profil_id = this.current_profil;
			let data = this.annee ? this.telechargements.data_by_years[this.annee]['total_acces'] : 0;
			
			if (profil_id != 'tous') {
				data = this.annee ? this.telechargements.data_profils[profil_id].data_by_years[this.annee]['total_acces'] : 0;
			}

			return data
		},
		getTotal1(){
			let profil_id = this.current_profil;
			let data = this.annee ? this.telechargements.data_by_years[this.annee]['total_month'] : 0;
			
			if (profil_id != 'tous') {
				data = this.annee ? this.telechargements.data_profils[profil_id].data_by_years[this.annee]['total_month'] : 0;
			}

			return data
		},
		getTotal2(){

			let profil_id = this.current_profil;
			let data = this.annee ? this.telechargements.data_by_years[this.annee]['exported_sites'] : 0;
			
			if (profil_id != 'tous') {
				data = this.annee ? this.telechargements.data_profils[profil_id].data_by_years[this.annee]['exported_sites'] : 0;
			}

			return data
		},
		getTotal3(){

			let profil_id = this.current_profil;
			let data = this.annee ? this.telechargements.data_by_years[this.annee]['consumed_credits'] : 0;
			
			if (profil_id != 'tous') {
				data = this.annee ? this.telechargements.data_profils[profil_id].data_by_years[this.annee]['consumed_credits'] : 0;
			}

			return data
		},
		date(){
			return new Date().toLocaleDateString('fr-fr', { year:"numeric", month:"short", day:"numeric"})
		},
		pourcentRemaining(){
			if(!this.telechargements || !this.telechargements.remaining_credits || !this.telechargements.total_bought_credits) return '0%';
			let pourcent = 100*this.telechargements.remaining_credits/this.telechargements.total_bought_credits
			pourcent += '%'
			return pourcent
		}
	},
	components:{
		SousMenu: () => import('./sous_menu.vue'),
		CustomSelect
	}
}